<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <div class="flex justify-between flex-wrap">
            <v-dialog v-model="dialog" max-width="600px">
              <template v-if="addProduct" v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mb-2 mt-10 button"
                  dark
                  color="info"
                  v-bind="attrs"
                  v-on="on"
                  @click="reset"
                >
                  <v-icon>mdi-plus</v-icon>
                  Add Product
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline" v-if="isEditing">Edit Product</span>
                  <span class="headline" v-else>Add Product</span>
                </v-card-title>

                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <label for="">Product Name</label>
                          <v-text-field
                            filled
                            color="info"
                            v-model="productName"
                            :rules="emptyRules"
                          ></v-text-field>
                          <div v-if="!isEditing">
                            <label for="">Code</label>
                            <v-text-field
                              filled
                              color="info"
                              v-model="productCode"
                              :rules="emptyRules"
                            ></v-text-field>
                          </div>

                          <label for="">Price</label>
                          <v-text-field
                            filled
                            color="info"
                            v-model="productPrice"
                            :rules="emptyRules"
                            type="number"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-container>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn class="m-2" color="error" @click="close"
                        >Cancel</v-btn
                      >
                      <v-btn
                        v-if="!isEditing"
                        class="m-2"
                        color="info"
                        @click="save"
                        >Save Product</v-btn
                      >
                      <v-btn
                        v-else
                        class="m-2"
                        color="info"
                        @click="updateProduct"
                        >Update Product</v-btn
                      >
                    </v-card-actions>
                  </v-container>
                </v-form>
              </v-card>
            </v-dialog>

            <div></div>
          </div>
        </v-card-title>

        <paginated-data-table
          v-model="selected"
          :loading="loading"
          :headers="headers"
          :items="items.products"
          :totalPages="items.lastPage"
          :isStoreStock="false"
          title="Product"
          itemKey="code"
          url="/app/product/view/"
          class="elevation-1 table-one"
          multi-sort
          @next="loadProducts"
          @edit="edit"
          @searchWithCode="searchWithCode"
          @delete="deleteProduct"
        >
        </paginated-data-table>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
import {
  VIEW_ALL_PRODUCTS,
  ADD_PRODUCT,
  DELETE_PRODUCT,
  UPDATE_PRODUCT,
  GET_PRODUCT_BY_CODE,
} from "../../../constants/graphql";
import Swal from "sweetalert2";
import { handleError } from "../../../constants/error";

export default {
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Product",
  },

  data() {
    return {
      isEditing: false,
      viewDialog: false,
      search: "",
      selected: [],
      userPermission: this.$store.state.authData.userPermissions,
      headers: [
        {
          text: "S/N",
          value: "sno",
          width: "10%",
        },
        {
          text: "Name",
          sortable: false,
          value: "name",
        },
        { text: "Price (₦)", value: "price" },
        { text: "Code", value: "code" },
        { text: "Action", value: "action" },
      ],
      items: {
        products: [],
        lastPage: 0,
      },
      defaultItems: {
        products: [],
        lastPage: 0,
      },
      productName: "",
      productPrice: "",
      productCode: "",
      dialog: false,
      loading: false,
      productId: null,
      emptyRules: [(v) => !!v || "Field is required"],
      valid: true,
      currentPage: 1,
    };
  },
  computed: {
    addProduct() {
      if (this.userPermission.includes("add_product")) {
        return true;
      }
    },
  },
  apollo: {
    items: {
      query: VIEW_ALL_PRODUCTS,
      variables() {
        return {
          currentPage: this.currentPage,
          perPage: 30,
        };
      },
      update(result) {
        this.loading = false;
        return result.getProductList;
      },
      error(error) {
        this.loading = false;
        handleError(error);
      },
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  methods: {
    close() {
      this.dialog = false;
      this.isEditing = false;
    },
    default() {
      this.items = this.defaultItems;
      this.loading = true;
    },
    save() {
      let validated = this.$refs.form.validate();
      if (validated === true) {
        this.loading = true;
        this.$apollo
          .mutate({
            mutation: ADD_PRODUCT,
            variables: {
              name: this.productName,
              price: parseFloat(this.productPrice),
              code: this.productCode,
            },
          })
          .then((result) => {
            let resp = result.data.createProduct;
            let dialogText = `
        <p>${resp.name} has been added to MayVentory successfully.</p>
        <p>Product details are:</p>
        <p><strong>Price:</strong>₦ ${resp.price} </p>
        <p><strong>Code:</strong> ${resp.code}</p>
        `;
            Swal.fire({
              html: `<div>${dialogText}</div>`,
              icon: "success",
              customClass: {
                popup: "format-pre",
              },
            });
            this.$apollo.queries.items.refetch();
          });
        this.loading = false;
        this.close();
      }
    },

    reset() {
      this.productName = "";
      this.productPrice = "";
      this.productCode = "";

      this.isEditing = false;
      this.dialog = true;
    },

    edit(item) {
      this.productName = item.name;
      this.productPrice = item.price;
      this.productId = item.id;
      this.productCode = item.code;

      this.isEditing = true;
      this.dialog = true;

      this.loading = true;
      this.$apollo.queries.items.refetch();
    },
    vDialog(item) {
      this.productName = item.name;
      this.productPrice = item.price;
      this.productId = item.id;
      this.productCode = item.code;
      this.viewDialog = true;
      this.loading = true;
      this.$apollo.queries.items.refetch();
    },

    updateProduct() {
      this.loading = true;
      this.$apollo
        .mutate({
          mutation: UPDATE_PRODUCT,
          variables: {
            id: this.productId,
            name: this.productName,
            price: parseFloat(this.productPrice),
          },
        })
        .then((_) => {
          this.$apollo.queries.items.refetch();
          Swal.fire({
            text: "Product Update successful",
            icon: "success",
          });
        });
      this.loading = false;
      this.close();
    },

    deleteProduct(productId, productName) {
      this.$swal
        .fire({
          text: `Do you wish to delete ${productName}?`,
          icon: "question",
          showConfirmButton: true,
          confirmButtonText: "Yes",
          showDenyButton: true,
        })
        .then((result) => {
          this.loading = true;
          if (result.isConfirmed) {
            this.$apollo
              .mutate({
                mutation: DELETE_PRODUCT,
                variables: {
                  productId: productId,
                },
              })
              .then((_) => {
                this.$apollo.queries.items.refetch();
                this.$swal.fire({
                  toast: true,
                  text: `${productName} Deleted from MayVentory`,
                  icon: "success",
                  timer: 3000,
                  timerProgressBar: true,
                  position: "top-end",
                });
              });
          }
          this.loading = false;
        });
    },

    loadProducts(page) {
      this.items = this.defaultItems;
      this.currentPage = page;
      this.loading = true;
      this.$apollo.queries.items.refetch();
    },

    searchWithCode(value) {
      this.default();
      this.productCode = value;
      this.$apollo
        .query({
          query: GET_PRODUCT_BY_CODE,
          variables: {
            productCode: this.productCode,
          },
        })
        .then((result) => {
          let data = result.data.getProductByCode
            ? [result.data.getProductByCode]
            : [];
          this.items = {
            products: data,
            lastPage: data.length,
          };
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

::v-deep .table-one {
  thead.v-data-table-header {
    tr {
      &:hover {
        background-color: #f2f3f8;
      }
      th {
        span {
          font-size: 16px;
          color: #304156;
        }
      }
    }
    tr {
      td {
        padding-bottom: 30px;
        padding-top: 30px;
      }
    }
  }
  tbody {
    tr {
      &:hover {
        background-color: #f2f3f8 !important;
      }
      td {
        padding: 0.95rem !important;
      }
    }
  }

  .format-pre pre {
    background: #49483e;
    color: #f7f7f7;
    padding: 10px;
    font-size: 14px;
  }
}
</style>
